import {
  searchUsersStart, searchUsersSuccess, searchUsersFail,
  searchAllUsersStart, searchAllUsersSuccess, searchAllUsersFail,
  searchUsersFilterStart, searchUsersFilterSuccess, searchUsersFilterFail,
  searchStatisticsUsersStart, searchStatisticsUsersSuccess, searchStatisticsUsersFail,
  getNotifyEnabledStart, getNotifyEnabledSuccess, getNotifyEnabledFail,
  setNotifyEnabledStart, setNotifyEnabledSuccess, setNotifyEnabledFail,
  searchNotInvolvedUsersStart,
  searchNotInvolvedUsersSuccess,
  searchNotInvolvedUsersFail,
} from "../actions/searchUsersActions";
import CommonUtils from "../../../utils/CommonUtils";

export const searchUsers = (currentPage, filter = {}, groupData = {}) => ((dispatch, getState) => {
  dispatch(searchUsersStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", "admin/searchUsers/0/0?cnt", filter, config)
    .then(usersCountResponse => {
      if (usersCountResponse.data !== null && parseInt(usersCountResponse.data) === 0) {
        dispatch(searchUsersSuccess({
          usersList: [],
          totalUsers: 0,
          filter,
        }));
      }
      else if (usersCountResponse.data !== null && !usersCountResponse.data.error) {
        const perPage = 10;
        const offset = (currentPage - 1) * perPage;
        const totalUsers = usersCountResponse.data;

        CommonUtils.apiCall("get", "private", `admin/searchUsers/${offset}/${perPage}`, filter, config)
          .then(response => {
            if (Array.isArray(response.data)) {
              const scrolledUsers = response.data;
              const oldUsers = getState().users.usersList ? getState().users.usersList : [];
              const updatedUsers = currentPage === 1 ? scrolledUsers : oldUsers.concat(scrolledUsers);

              dispatch(searchUsersSuccess({
                usersList: updatedUsers,
                totalUsers,
                filter,
                currentPage: currentPage + 1,
              }));
            }
            else {
              dispatch(searchUsersFail(response.data.error));
            }
          })
          .catch(() => dispatch(searchUsersFail("Error")));
      }
      else {
        dispatch(searchUsersFail(usersCountResponse.data.error));
      }
    })
    .catch(() => dispatch(searchUsersFail("Error")));
});

export const searchUsersFilter = params => ((dispatch, getState) => {
  dispatch(searchUsersFilterStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", "admin/searchUsers/0/100", params, config)
    .then(response => {
      if (Array.isArray(response.data)) {
        dispatch(searchUsersFilterSuccess({
          usersSearchList: response.data
        }));
      }
      else {
        dispatch(searchUsersFilterFail("Error"));
      }
    })
    .catch(() => dispatch(searchUsersFilterFail("Error")));
});

export const searchStatisticsUsers = (businessID, params) => ((dispatch, getState) => {
  dispatch(searchStatisticsUsersStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `admin/searchBusinessUsers/${businessID}/0/100`, params, config)
    .then(response => {
      if (Array.isArray(response.data)) {
        dispatch(searchStatisticsUsersSuccess({
          statisticsUsers: response.data,
        }));
      }
      else {
        dispatch(searchStatisticsUsersFail("Error"));
      }
    })
    .catch(() => dispatch(searchStatisticsUsersFail("Error")));
});

export const searchAllUsers = () => ((dispatch, getState) => {
  dispatch(searchAllUsersStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", "admin/searchUsers/0/0?full", {}, config)
    .then(response => {
      if (Array.isArray(response.data)) {
        dispatch(searchAllUsersSuccess({
          usersList: response.data,
        }));
      }
      else {
        dispatch(searchAllUsersFail("Error"));
      }
    })
    .catch(() => dispatch(searchAllUsersFail("Error")));
});

export const searchNotInvolvedUsers = ({ businessID, params, hardCodeOffset }) => (async (dispatch, getState) => {
  dispatch(searchNotInvolvedUsersStart());

  const { xID, xTOKEN } = getState().user;
  let paramsObj = params || {};
  const config = {
    id: xID,
    token: xTOKEN
  };

  const currentPage = hardCodeOffset !== undefined ? 1 : getState().users.investorsCurrentPage;
  const perPage = 100;
  const offset = hardCodeOffset !== undefined ? hardCodeOffset : (currentPage - 1) * perPage;
  
  try {
    const allCntResponse = await CommonUtils.apiCall("get", "private", `admin/searchUsers/${offset}/${perPage}?cnt&notInvolvedIn=${businessID}`, paramsObj, config);
  
    if(allCntResponse != null && allCntResponse?.data) {
      const investorsResponse = await CommonUtils.apiCall("get", "private", `admin/searchUsers/${offset}/${perPage}?notInvolvedIn=${businessID}`, paramsObj, config);
  
      if (Array.isArray(investorsResponse.data)) {
        const scrolledInvestors = investorsResponse.data;
        const oldInvestors = getState().users.notInvolvedUsers ? getState().users.notInvolvedUsers : [];
        const updatedInvestors = hardCodeOffset !== undefined ? scrolledInvestors : oldInvestors.concat(scrolledInvestors);

        dispatch(searchNotInvolvedUsersSuccess({
          notInvolvedUsers: updatedInvestors,
          notInvolvedUsersCount: allCntResponse.data,
          investorsCurrentPage: currentPage + 1,
        }));
      }
      else {
        dispatch(searchNotInvolvedUsersFail("Error"));
      }
    }
  } catch(err) {
    dispatch(searchNotInvolvedUsersFail("Error"));
  }
});

export const getNotifyEnabled = () => ((dispatch, getState) => {
  dispatch(getNotifyEnabledStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", "admin/getNotifyEnabled", {}, config)
    .then(response => {
      const notifyEnabled = +response.data.success ? false : true;
      dispatch(getNotifyEnabledSuccess({
        notifyEnabled,
      }));
    })
    .catch(() => dispatch(getNotifyEnabledFail("Error")));
});

export const setNotifyEnabled = (enabled) => ((dispatch, getState) => {
  dispatch(setNotifyEnabledStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "admin/setNotifyEnabled", { enabled }, config)
    .then(response => {
      const notifyEnabled = +enabled ? false : true;
      dispatch(setNotifyEnabledSuccess({
        notifyEnabled,
      }));
    })
    .catch(() => dispatch(setNotifyEnabledFail("Error")));
});
