import {
  getBusinessStart, getBusinessSuccess, getBusinessFail,
  getBusinessesStart, getBusinessesSuccess, getBusinessesFail,
  getPursuingBusinessesStart, getPursuingBusinessesSuccess, getPursuingBusinessesFail,
  getBusinessesByNameStart, getBusinessesByNameSuccess, getBusinessesByNameFail,
  setSavedStart, setSavedFail,
  setInterestedStart, setInterestedSuccess, setInterestedFail,
  readyToStartStart, readyToStartSuccess, readyToStartFail,
  readyToCommitStart, readyToCommitSuccess, readyToCommitFail,
  readyToCloseStart, readyToCloseSuccess, readyToCloseFail,
  searchBusinessUsersStart, searchBusinessUsersSuccess, searchBusinessUsersFail,
  allowCommitStart, allowCommitSuccess, allowCommitFail,
  allowCloseStart, allowCloseSuccess, allowCloseFail,
  closeStart, closeSuccess, closeFail,
  reopenStart, reopenSuccess, reopenFail,
  setInvestmentInfoStart, setInvestmentInfoSuccess, setInvestmentInfoFail,
  setBusinessExpiredStart, setBusinessExpiredSuccess, setBusinessExpiredFail, getBusinessesSummaryStart, getBusinessesSummarySuccess, getBusinessesSummaryFail, SetPegateShareholdingStart, SetPegateShareholdingFail, SetPegateShareholdingSuccess, removeBusinessInvestorStart, removeBusinessInvestorSuccess, removeBusinessInvestorFail, searchBusinessUserBidsStart, searchBusinessUserBidsSuccess, searchBusinessUserBidsFail,
  searchBusinessInvolveRequestsStart,
  searchBusinessInvolveRequestsSuccess,
  searchBusinessInvolveRequestsFail,
  addInvolveRequestSuccess,
  addInvolveRequestStart,
  addInvolveRequestFail,
  getBusinessInvolveRequestStart,
  getBusinessInvolveRequestSuccess,
  getBusinessInvolveRequestFail,
  acceptInvolveRequestSuccess,
  acceptInvolveRequestStart,
  acceptInvolveRequestFail,
  rejectInvolveRequestStart,
  rejectInvolveRequestSuccess,
  rejectInvolveRequestFail,
  setCommentsStart,
  setCommentsSuccess,
  setCommentsFail,
} from "../actions/businessActions";
import { getStates } from "../../dictionaries/thunks/dictionariesThunks";
import CommonUtils from "../../../utils/CommonUtils";
import { getOpportunitiesByType } from "../../../utils/getOpportunitiesByType";

export const getBusiness = (businessID) => ((dispatch, getState) => {
  dispatch(getBusinessStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `shared/getBusiness/${businessID}`, {}, config)
    .then(response => {
      if (typeof response.data.success === "object" && response.data.success != null) {
        // from API we are not getting an opportunity ID, that's why we write id for ourselves
        const currentOpenedBusiness = response.data.success;
        currentOpenedBusiness.id = businessID;
        dispatch(getStates(currentOpenedBusiness.country));
        dispatch(getBusinessSuccess({ currentOpenedBusiness }));
      }
      else {
        dispatch(getBusinessFail(response.data.error));
      }
    })
    .catch(() => dispatch(getBusinessFail("Error")));
});


export const getBusinessInvolveRequest = (businessID) => ((dispatch, getState) => {
  dispatch(getBusinessInvolveRequestStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `investor/getBusinessInvolveRequest/${businessID}`, {}, config)
    .then(response => {
      if (response.data && !response.data.hasOwnProperty("error")) {
        dispatch(getBusinessInvolveRequestSuccess({ involveRequest: response.data }));
      }
      else {
        dispatch(getBusinessInvolveRequestFail(response.data.error));
      }
    })
    .catch(() => dispatch(getBusinessInvolveRequestFail("Error")));
});



export const businessSummary = (businessID) => ((dispatch, getState) => {
  dispatch(getBusinessesSummaryStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `shared/businessSummary/${businessID}`, {}, config)
    .then(response => {
      if (response.data.success) {
        dispatch(getBusinessesSummarySuccess(response.data.success));
      }
      else {
        dispatch(getBusinessesSummaryFail(response.data.error));
      }
    })
    .catch(() => dispatch(getBusinessesSummaryFail("Error")));
});



export const getBusinesses = (hardCodeOffset) => ((dispatch, getState) => {
  dispatch(getBusinessesStart());

  const { filter } = getState().business;
  const currentPage = hardCodeOffset !== undefined ? 1 : getState().business.currentPage;
  const { xID, xTOKEN, role } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };
  const perPage = 12;
  const offset = hardCodeOffset !== undefined ? hardCodeOffset : (currentPage - 1) * perPage;

  const enabledFilter = role !== "ADMIN" ? { enabled: '' } : {};

  CommonUtils.apiCall("get", "private", `shared/getBusinesses/${offset}/${perPage}`, { ...enabledFilter, ...filter }, config)
    .then((response) => {
      if (Array.isArray(response.data)) {
        CommonUtils.apiCall("get", "private", `shared/getBusinesses/${offset}/${perPage}?cnt&interested`, { ...enabledFilter, filter }, config)
          .then(likedBusinessItemsCountResponse => {
            if (likedBusinessItemsCountResponse.data != null) {
              const likedBusinessItemsCount = likedBusinessItemsCountResponse.data;
              CommonUtils.apiCall("get", "private", `shared/getBusinesses/${offset}/${perPage}?interested`, { ...enabledFilter, ...filter }, config)
                .then(likedBusinessItemsResponse => {
                  if (Array.isArray(likedBusinessItemsResponse.data)) {
                    CommonUtils.apiCall("get", "private", `shared/getBusinesses/${offset}/${perPage}?cnt&notInterested`, { ...enabledFilter, ...filter }, config)
                      .then(notInterestedBusinessItemsCountResponse => {
                        if (notInterestedBusinessItemsCountResponse.data != null) {
                          const notInterestedBusinessItemsCount = notInterestedBusinessItemsCountResponse.data;
                          CommonUtils.apiCall("get", "private", `shared/getBusinesses/${offset}/${perPage}?notInterested`, { ...enabledFilter, ...filter }, config)
                            .then(notInterestedBusinessItemsResponse => {
                              if (Array.isArray(notInterestedBusinessItemsResponse.data)) {
                                CommonUtils.apiCall("get", "private", `shared/getBusinesses/${offset}/${perPage}?cnt&pursuing`, { ...enabledFilter, ...filter }, config)
                                  .then(pursuingBusinessItemsCountResponse => {
                                    if (pursuingBusinessItemsCountResponse.data != null) {
                                      const pursuingBusinessItemsCount = pursuingBusinessItemsCountResponse.data;
                                      CommonUtils.apiCall("get", "private", `shared/getBusinesses/${offset}/${perPage}?pursuing`, { ...enabledFilter, ...filter }, config)
                                        .then(pursuingBusinessItemsResponse => {
                                          if (Array.isArray(pursuingBusinessItemsResponse.data)) {
                                            CommonUtils.apiCall("get", "private", `shared/getBusinesses/${offset}/${perPage}?cnt&closed`, { ...enabledFilter, ...filter }, config)
                                              .then(closedBusinessItemsCountResponse => {
                                                if (closedBusinessItemsCountResponse.data != null) {
                                                  const closedBusinessItemsCount = closedBusinessItemsCountResponse.data;
                                                  CommonUtils.apiCall("get", "private", `shared/getBusinesses/${offset}/${perPage}?closed`, { ...enabledFilter, ...filter }, config)
                                                    .then(closedBusinessItemsResponse => {
                                                      if (Array.isArray(closedBusinessItemsResponse.data)) {
                                                        CommonUtils.apiCall("get", "private", `shared/getBusinesses/${offset}/${perPage}?cnt&saved`, { ...enabledFilter, ...filter }, config)
                                                          .then(newBusinessTypeItemsCountResponse => {
                                                            if (newBusinessTypeItemsCountResponse.data != null) {
                                                              const newBusinessTypeItemsCount = newBusinessTypeItemsCountResponse.data;
                                                              CommonUtils.apiCall("get", "private", `shared/getBusinesses/${offset}/${perPage}?saved`, { ...enabledFilter, ...filter }, config)
                                                                .then(newBusinessTypeItemsResponse => {
                                                                  if (Array.isArray(newBusinessTypeItemsResponse.data)) {
                                                                    CommonUtils.apiCall("get", "private", `shared/getBusinesses/${offset}/${perPage}?cnt&ignored`, { ...enabledFilter, ...filter }, config)
                                                                      .then(ignoredBusinessTypeItemsCountResponse => {
                                                                        if (ignoredBusinessTypeItemsCountResponse.data != null) {
                                                                          const ignoredBusinessTypeItemsCount = ignoredBusinessTypeItemsCountResponse.data;
                                                                          CommonUtils.apiCall("get", "private", `shared/getBusinesses/${offset}/${perPage}?ignored`, { ...enabledFilter, ...filter }, config)
                                                                            .then(ignoredBusinessItemsResponse => {
                                                                              if (Array.isArray(ignoredBusinessItemsResponse.data)) {
                                                                                const scrolledBusinesses = response.data;
                                                                                const likedBusinessItems = likedBusinessItemsResponse.data;
                                                                                const notInterestedBusinessItems = notInterestedBusinessItemsResponse.data;
                                                                                const pursuingBusinessItems = pursuingBusinessItemsResponse.data;
                                                                                const closedBusinessItems = closedBusinessItemsResponse.data;
                                                                                const newBusinessTypeItems = newBusinessTypeItemsResponse.data;
                                                                                const ignoredBusinessTypeItems = ignoredBusinessItemsResponse.data;

                                                                                const oldBusinesses = getState().business.businesses ? getState().business.businesses : [];
                                                                                const oldLikedBusinesses = getState().business.likedBusinessItems ? getState().business.likedBusinessItems : [];
                                                                                const oldNotInterestedBusinesses = getState().business.notInterestedBusinessItems ? getState().business.notInterestedBusinessItems : [];
                                                                                const oldPursuingBusinesses = getState().business.pursuingBusinessItems ? getState().business.pursuingBusinessItems : [];
                                                                                const oldClosedBusinesses = getState().business.closedBusinessItems ? getState().business.closedBusinessItems : [];
                                                                                const oldNewBusinessTypeItems = getState().business.newBusinessTypeItems ? getState().business.newBusinessTypeItems : [];
                                                                                const oldIgnoredBusinessTypeItems = getState().business.ignoredBusinessTypeItems ? getState().business.ignoredBusinessTypeItems : [];

                                                                                const updatedBusinesses = hardCodeOffset !== undefined ? scrolledBusinesses : oldBusinesses.concat(scrolledBusinesses);
                                                                                const updatedLikedBusinesses = hardCodeOffset !== undefined ? likedBusinessItems : oldLikedBusinesses.concat(likedBusinessItems);
                                                                                const updatedNotInterestedBusinesses = hardCodeOffset !== undefined ? notInterestedBusinessItems : oldNotInterestedBusinesses.concat(notInterestedBusinessItems);
                                                                                const updatedPursuingBusinesses = hardCodeOffset !== undefined ? pursuingBusinessItems : oldPursuingBusinesses.concat(pursuingBusinessItems);
                                                                                const updatedClosedBusinesses = hardCodeOffset !== undefined ? closedBusinessItems : oldClosedBusinesses.concat(closedBusinessItems);
                                                                                const updatedNewBusinessTypeItems = hardCodeOffset !== undefined ? newBusinessTypeItems : oldNewBusinessTypeItems.concat(newBusinessTypeItems);
                                                                                const updatedIgnoredBusinessTypeItems = hardCodeOffset !== undefined ? ignoredBusinessTypeItems : oldIgnoredBusinessTypeItems.concat(ignoredBusinessTypeItems);

                                                                                const savedBusinesses = getOpportunitiesByType(updatedBusinesses, "saved");
                                                                                const pursuingBusinesses = getOpportunitiesByType(updatedBusinesses, "pursuing");
                                                                                const closedBusinesses = getOpportunitiesByType(updatedBusinesses, "closed");
                                                                                const { businessesCount } = getState().business;

                                                                                dispatch(getBusinessesSuccess({
                                                                                  businesses: updatedBusinesses,
                                                                                  savedBusinesses,
                                                                                  pursuingBusinesses,
                                                                                  closedBusinesses,
                                                                                  filter,
                                                                                  businessesCount,
                                                                                  currentPage: currentPage + 1,
                                                                                  likedBusinessItems: updatedLikedBusinesses,
                                                                                  notInterestedBusinessItems: updatedNotInterestedBusinesses,
                                                                                  pursuingBusinessItems: updatedPursuingBusinesses,
                                                                                  closedBusinessItems: updatedClosedBusinesses,
                                                                                  newBusinessTypeItems: updatedNewBusinessTypeItems,
                                                                                  ignoredBusinessTypeItems: updatedIgnoredBusinessTypeItems,
                                                                                  likedBusinessItemsCount,
                                                                                  notInterestedBusinessItemsCount,
                                                                                  pursuingBusinessItemsCount,
                                                                                  closedBusinessItemsCount,
                                                                                  newBusinessTypeItemsCount,
                                                                                  ignoredBusinessTypeItemsCount,
                                                                                }));
                                                                              }
                                                                              else {
                                                                                dispatch(getBusinessesFail("Error"));
                                                                              }
                                                                            })
                                                                            .catch(() => dispatch(getBusinessesFail("Error")));
                                                                        }
                                                                        else {
                                                                          dispatch(getBusinessesFail("Error"));
                                                                        }
                                                                      })
                                                                      .catch(() => dispatch(getBusinessesFail("Error")));
                                                                  }
                                                                  else {
                                                                    dispatch(getBusinessesFail(newBusinessTypeItemsResponse.data.error));
                                                                  }
                                                                })
                                                                .catch(() => dispatch(getBusinessesFail('Error')));
                                                            }
                                                          })
                                                          .catch(() => dispatch(getBusinessesFail('Error')));
                                                      }
                                                      else {
                                                        dispatch(getBusinessFail(closedBusinessItemsResponse.data.error));
                                                      }
                                                    })
                                                    .catch(() => dispatch(getBusinessFail("Error")));
                                                }
                                                else {
                                                  dispatch(getBusinessesFail("Error"));
                                                }
                                              })
                                              .catch(() => dispatch(getBusinessesFail("Error")));
                                          }
                                          else {
                                            dispatch(getBusinessFail(pursuingBusinessItemsResponse.data.error));
                                          }
                                        })
                                        .catch(() => dispatch(getBusinessFail("Error")));
                                    }
                                    else {
                                      dispatch(getBusinessesFail("Error"));
                                    }
                                  })
                                  .catch(() => dispatch(getBusinessesFail("Error")));
                              }
                              else {
                                dispatch(getBusinessesFail("Error"));
                              }
                            })
                            .catch(() => dispatch(getBusinessesFail("Error")));
                        }
                      })
                      .catch(() => getBusinessesFail("Error"));
                  }
                  else {
                    dispatch(getBusinessFail(likedBusinessItemsResponse.data.error));
                  }
                })
                .catch(() => dispatch(getBusinessFail("Error")));
            }
            else {
              dispatch(getBusinessesFail("Error"));
            }
          })
          .catch(() => dispatch(getBusinessesFail("Error")));
      }
      else {
        dispatch(getBusinessesFail(response.data.error));
      }
    })
    .catch(() => dispatch(getBusinessesFail("Error")));
});

export const getPursuingBusinesses = () => ((dispatch, getState) => {
  dispatch(getPursuingBusinessesStart());

  const { xID, xTOKEN, role } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };
  const currentDataRoomVirtualItemsPage = getState().business.currentDataRoomVirtualItemsPage;
  const perPage = 10;
  const offset = (currentDataRoomVirtualItemsPage - 1) * perPage; // (currentPage + 1) - 1 for avoiding extra same call at the first time

  const enabledFilter = role !== "ADMIN" ? { enabled: '' } : {};

  CommonUtils.apiCall("get", "private", `shared/getBusinesses/${offset}/${perPage}?cnt`, enabledFilter, config)
    .then(pursuingBusinessItemsCountResponse => {
      if (pursuingBusinessItemsCountResponse.data != null) {
        const pursuingBusinessItemsCount = pursuingBusinessItemsCountResponse.data;
        CommonUtils.apiCall("get", "private", `shared/getBusinesses/${offset}/${perPage}?pursuing`, enabledFilter, config)
          .then(pursuingBusinessItemsResponse => {
            if (Array.isArray(pursuingBusinessItemsResponse.data)) {
              const pursuingBusinessItems = pursuingBusinessItemsResponse.data;
              const oldPursuingBusinesses = getState().business.dataRoomVirtualItems ? getState().business.dataRoomVirtualItems : [];
              const updatedPursuingBusinesses = oldPursuingBusinesses.concat(pursuingBusinessItems);

              dispatch(getPursuingBusinessesSuccess({
                dataRoomVirtualItems: updatedPursuingBusinesses,
                currentDataRoomVirtualItemsPage: currentDataRoomVirtualItemsPage + 1,
                dataRoomVirtualItemsCount: pursuingBusinessItemsCount,
              }));
            } else {
              dispatch(getPursuingBusinessesFail("Error"));
            }
          })
          .catch(() => getPursuingBusinessesFail("Error"));
      } else {
        dispatch(getPursuingBusinessesFail("Error"));
      }
    })
    .catch(() => dispatch(getPursuingBusinessesFail("Error")));
});

export const getBusinessesByName = businessName => ((dispatch, getState) => {
  dispatch(getBusinessesByNameStart());

  const { xID, xTOKEN, role } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };
  const param = {
    name: businessName
  };

  const enabledFilter = role !== "ADMIN" ? { enabled: '' } : {};

  CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0", { ...enabledFilter, ...param }, config)
    .then(response => {
      if (Array.isArray(response.data)) {
        dispatch(getBusinessesByNameSuccess({
          businessesSearchList: response.data
        }));
      }
    })
    .catch(() => dispatch(getBusinessesByNameFail("Error")));
});

export const filterBusinesses = (queryStringParams) => ((dispatch, getState) => {
  dispatch(getBusinessesStart());

  const { xID, xTOKEN, role } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  const enabledFilter = role !== "ADMIN" ? { enabled: '' } : {};

  CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt", { ...enabledFilter, ...queryStringParams }, config)
    .then(businessItemsCountResponse => {
      if (businessItemsCountResponse.data != null && parseInt(businessItemsCountResponse.data) === 0) {
        dispatch(getBusinessesSuccess({
          businesses: [],
          savedBusinesses: [],
          notInterestedBusinessItems: [],
          pursuingBusinesses: [],
          closedBusinesses: [],
          filter: queryStringParams,
        }));
      }
      else if (businessItemsCountResponse.data != null) {
        const businessItemsCount = businessItemsCountResponse.data;
        CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt&interested", { ...enabledFilter, ...queryStringParams }, config)
          .then(likedBusinessItemsCountResponse => {
            if (likedBusinessItemsCountResponse.data != null) {
              const likedBusinessItemsCount = likedBusinessItemsCountResponse.data;
              CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt&pursuing", { ...enabledFilter, ...queryStringParams }, config)
                .then(pursuingBusinessItemsCountResponse => {
                  if (pursuingBusinessItemsCountResponse.data != null) {
                    const pursuingBusinessItemsCount = pursuingBusinessItemsCountResponse.data;
                    CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt&closed", { ...enabledFilter, ...queryStringParams }, config)
                      .then(closedBusinessItemsCountResponse => {
                        if (closedBusinessItemsCountResponse.data != null) {
                          const closedBusinessItemsCount = closedBusinessItemsCountResponse.data;
                          CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt&saved", { ...enabledFilter, ...queryStringParams }, config)
                            .then(newBusinessTypeItemsCountResponse => {
                              if (newBusinessTypeItemsCountResponse.data != null) {
                                const newBusinessTypeItemsCount = newBusinessTypeItemsCountResponse.data;
                                CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?saved", { ...enabledFilter, ...queryStringParams }, config)
                                  .then(newBusinessTypeItemsResponse => {
                                    if (Array.isArray(newBusinessTypeItemsResponse.data)) {
                                      CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/10", { ...enabledFilter, ...queryStringParams }, config)
                                        .then((response) => {
                                          if (Array.isArray(response.data)) {
                                            CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/10?interested", { ...enabledFilter, ...queryStringParams }, config)
                                              .then(likedBusinessItemsResponse => {
                                                if (Array.isArray(likedBusinessItemsResponse.data)) {
                                                  CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/10?pursuing", { ...enabledFilter, ...queryStringParams }, config)
                                                    .then(pursuingBusinessItemsResponse => {
                                                      if (Array.isArray(pursuingBusinessItemsResponse.data)) {
                                                        CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/10?closed", { ...enabledFilter, ...queryStringParams }, config)
                                                          .then(closedBusinessItemsResponse => {
                                                            if (Array.isArray(closedBusinessItemsResponse.data)) {
                                                              CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt&notInterested", { ...enabledFilter, ...queryStringParams }, config)
                                                                .then(notInterestedBusinessItemsCountResponse => {
                                                                  if (notInterestedBusinessItemsCountResponse.data != null) {
                                                                    const notInterestedBusinessItemsCount = notInterestedBusinessItemsCountResponse.data;
                                                                    CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?notInterested", { ...enabledFilter, ...queryStringParams }, config)
                                                                      .then(notInterestedBusinessItemsResponse => {
                                                                        if (Array.isArray(notInterestedBusinessItemsResponse.data)) {
                                                                          CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt&ignored", { ...enabledFilter, ...queryStringParams }, config)
                                                                            .then(ignoredBusinessTypeItemsCountResponse => {
                                                                              if (ignoredBusinessTypeItemsCountResponse.data != null) {
                                                                                const ignoredBusinessTypeItemsCount = ignoredBusinessTypeItemsCountResponse.data;
                                                                                CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?ignored", { ...enabledFilter, ...queryStringParams }, config)
                                                                                  .then(ignoredBusinessItemsResponse => {
                                                                                    if (Array.isArray(ignoredBusinessItemsResponse.data)) {
                                                                                      const businesses = response.data;
                                                                                      const likedBusinessItems = likedBusinessItemsResponse.data;
                                                                                      const notInterestedBusinessItems = notInterestedBusinessItemsResponse.data;
                                                                                      const pursuingBusinessItems = pursuingBusinessItemsResponse.data;
                                                                                      const closedBusinessItems = closedBusinessItemsResponse.data;
                                                                                      const newBusinessTypeItems = newBusinessTypeItemsResponse.data;
                                                                                      const ignoredBusinessTypeItems = ignoredBusinessItemsResponse.data;

                                                                                      const savedBusinesses = getOpportunitiesByType(businesses, "saved");
                                                                                      const pursuingBusinesses = getOpportunitiesByType(businesses, "pursuing");
                                                                                      const closedBusinesses = getOpportunitiesByType(businesses, "closed");

                                                                                      dispatch(getBusinessesSuccess({
                                                                                        businesses,
                                                                                        savedBusinesses,
                                                                                        pursuingBusinesses,
                                                                                        closedBusinesses,
                                                                                        filter: queryStringParams,
                                                                                        businessesCount: businessItemsCount,
                                                                                        likedBusinessItemsCount,
                                                                                        notInterestedBusinessItemsCount,
                                                                                        pursuingBusinessItemsCount,
                                                                                        closedBusinessItemsCount,
                                                                                        newBusinessTypeItemsCount,
                                                                                        ignoredBusinessTypeItemsCount,
                                                                                        currentPage: 1,
                                                                                        likedBusinessItems,
                                                                                        notInterestedBusinessItems,
                                                                                        pursuingBusinessItems,
                                                                                        closedBusinessItems,
                                                                                        newBusinessTypeItems,
                                                                                        ignoredBusinessTypeItems,
                                                                                      }));
                                                                                    }
                                                                                    else {
                                                                                      dispatch(getBusinessesFail("Error"));
                                                                                    }
                                                                                  })
                                                                                  .catch(() => dispatch(getBusinessesFail("Error")));
                                                                              }
                                                                              else {
                                                                                dispatch(getBusinessesFail("Error"));
                                                                              }
                                                                            })
                                                                            .catch(() => dispatch(getBusinessesFail("Error")));
                                                                        }
                                                                        else {
                                                                          dispatch(getBusinessesFail("Error"));
                                                                        }
                                                                      })
                                                                      .catch(() => dispatch(getBusinessesFail("Error")));
                                                                  }
                                                                })
                                                                .catch(() => dispatch(getBusinessesFail("Error")));
                                                            }
                                                            else {
                                                              dispatch(getBusinessesFail(closedBusinessItemsResponse.data.error));
                                                            }
                                                          })
                                                          .catch(() => dispatch(getBusinessesFail("Error")));
                                                      }
                                                      else {
                                                        dispatch(getBusinessesFail(pursuingBusinessItemsResponse.data.error));
                                                      }
                                                    })
                                                    .catch(() => dispatch(getBusinessesFail("Error")));
                                                }
                                                else {
                                                  dispatch(getBusinessesFail(likedBusinessItemsResponse.data.error));
                                                }
                                              })
                                              .catch(() => dispatch(getBusinessesFail("Error")));
                                          }
                                          else {
                                            dispatch(getBusinessesFail(response.data.error));
                                          }
                                        })
                                        .catch(() => dispatch(getBusinessesFail("No result")));
                                    }
                                    else {
                                      dispatch(getBusinessesFail(newBusinessTypeItemsResponse.data.error));
                                    }
                                  })
                              }
                              else {
                                dispatch(getBusinessesFail("Error"));
                              }
                            })
                            .catch(() => dispatch(getBusinessesFail("Error")));
                        }
                        else {
                          dispatch(getBusinessesFail("Error"));
                        }
                      })
                      .catch(() => dispatch(getBusinessesFail("Error")));
                  }
                  else {
                    dispatch(getBusinessesFail("Error"));
                  }
                })
                .catch(() => dispatch(getBusinessesFail("Error")));
            }
          })
          .catch(() => dispatch(getBusinessesFail("Error")));
      }
      else {
        dispatch(getBusinessesFail("Error"));
      }
    })
    .catch(() => dispatch(getBusinessesFail("Error")));
});

export const setSaved = (business, saved) => ((dispatch, getState) => {
  dispatch(setSavedStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };
  const params = { business, saved };

  CommonUtils.apiCall("post", "private", "investor/setSaved", params, config)
    .then((savedResponse) => {
      if (savedResponse.data.success) {
        dispatch(getBusinesses(0));
      }
    })
    .catch(() => dispatch(setSavedFail("Error")));
});

export const setInterested = (business, interested) => ((dispatch, getState) => {
  dispatch(setInterestedStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "investor/setInterested", { business, interested }, config)
    .then((response) => {
      if (response.data.success) {
        CommonUtils.apiCall("get", "private", `shared/getBusiness/${business}`, {}, config)
          .then(currentBusinessResponse => {
            if (typeof currentBusinessResponse.data.success === "object" && currentBusinessResponse.data.success != null) {
              const currentOpenedBusiness = currentBusinessResponse.data.success;
              currentOpenedBusiness.id = business;
              dispatch(setInterestedSuccess({
                currentOpenedBusiness
              }));
            }
            else {
              dispatch(setInterestedFail("Error"));
            }
          })
          .catch(() => dispatch(setInterestedFail("Error")));
        /*CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt", {}, config)
          .then(businessItemsCountResponse => {
            if (businessItemsCountResponse.data != null && parseInt(businessItemsCountResponse.data) === 0) {
              if (interested === 0) {
                history.push("/opportunities");
              }
              dispatch(setInterestedSuccess({businesses: []}));
            }
            else if (businessItemsCountResponse.data != null) {
              const businessItemsCount = businessItemsCountResponse.data;
              CommonUtils.apiCall("get", "private", `shared/getBusinesses/0/${businessItemsCount}`, {}, config)
                .then((businessesResponse) => {
                  if (Array.isArray(businessesResponse.data)) {
                    const businesses = businessesResponse.data;
                    const savedBusinesses = getOpportunitiesByType(businesses, "saved");
                    const pursuingBusinesses = getOpportunitiesByType(businesses, "pursuing");
                    const closedBusinesses = getOpportunitiesByType(businesses, "closed");

                    if (interested === 0) {
                      history.push("/opportunities");
                    }
                    dispatch(setInterestedSuccess({
                      businesses,
                      savedBusinesses,
                      pursuingBusinesses,
                      closedBusinesses
                    }));
                  }
                })
                .catch(() => dispatch(setInterestedFail("Error")));
            }
            else {
              dispatch(setInterestedFail("Error"));
            }
          })
          .catch(() => dispatch(setInterestedFail("Error")));*/
      }
    })
    .catch(() => dispatch(setInterestedFail("Error")));
});

export const readyToStart = (business, amount) => ((dispatch, getState) => {
  dispatch(readyToStartStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  const params = { business, amount };

  CommonUtils.apiCall("post", "private", "investor/readyToStart", params, config)
    .then(readyToStartResponse => {
      if (readyToStartResponse.data.success) {
        CommonUtils.apiCall("get", "private", `shared/getBusiness/${business}`, {}, config)
          .then(currentBusinessResponse => {
            if (typeof currentBusinessResponse.data.success === "object" && currentBusinessResponse.data.success != null) {
              const currentOpenedBusiness = currentBusinessResponse.data.success;
              currentOpenedBusiness.id = business;
              dispatch(readyToStartSuccess({
                currentOpenedBusiness
              }));
            }
            else {
              dispatch(readyToStartFail("Error"));
            }
          })
          .catch(() => dispatch(readyToCloseFail("Error")));
        /*CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt", {}, config)
          .then(businessItemsCountResponse => {
            if (businessItemsCountResponse.data != null && parseInt(businessItemsCountResponse.data) === 0) {
              dispatch(readyToStartSuccess({
                businesses: [],
                savedBusinesses: [],
                pursuingBusinesses: [],
                closedBusinesses: []
              }));
            }
            else if (businessItemsCountResponse.data != null) {
              const businessItemsCount = businessItemsCountResponse.data;
              CommonUtils.apiCall("get", "private", `shared/getBusinesses/0/${businessItemsCount}`, {}, config)
                .then(response => {
                  if (Array.isArray(response.data)) {
                    const businesses = response.data;
                    const savedBusinesses = getOpportunitiesByType(businesses, "saved");
                    const pursuingBusinesses = getOpportunitiesByType(businesses, "pursuing");
                    const closedBusinesses = getOpportunitiesByType(businesses, "closed");

                    dispatch(readyToStartSuccess({
                      businesses,
                      savedBusinesses,
                      pursuingBusinesses,
                      closedBusinesses
                    }));
                  }
                })
                .catch(() => dispatch(readyToStartFail("Error")));
            }
          })
          .catch(() => dispatch(readyToStartFail("Error")));*/
      }
      else {
        dispatch(readyToStartFail(readyToStartResponse.data.error));
      }
    })
    .catch(() => dispatch(readyToStartFail("Error")))
});

export const readyToCommit = business => ((dispatch, getState) => {
  dispatch(readyToCommitStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  const param = { business };

  CommonUtils.apiCall("post", "private", "investor/readyToCommit", param, config)
    .then(readyToCommitResponse => {
      if (readyToCommitResponse.data.success) {
        CommonUtils.apiCall("get", "private", `shared/getBusiness/${business}`, {}, config)
          .then(currentBusinessResponse => {
            if (typeof currentBusinessResponse.data.success === "object" && currentBusinessResponse.data.success != null) {
              const currentOpenedBusiness = currentBusinessResponse.data.success;
              currentOpenedBusiness.id = business;
              dispatch(readyToCommitSuccess({
                currentOpenedBusiness
              }));
            }
            else {
              dispatch(readyToCommitFail("Error"));
            }
          })
          .catch(() => dispatch(readyToCommitFail("Error")));
        /*CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt", {}, config)
          .then(businessItemsCountResponse => {
            if (businessItemsCountResponse.data != null && parseInt(businessItemsCountResponse.data) === 0) {
              dispatch(readyToCommitSuccess({
                businesses: [],
                savedBusinesses: [],
                pursuingBusinesses: [],
                closedBusinesses: []
              }));
            }
            else if (businessItemsCountResponse.data != null) {
              const businessItemsCount = businessItemsCountResponse.data;
              CommonUtils.apiCall("get", "private", `shared/getBusinesses/0/${businessItemsCount}`, {}, config)
                .then(response => {
                  if (Array.isArray(response.data)) {
                    const businesses = response.data;
                    const savedBusinesses = getOpportunitiesByType(businesses, "saved");
                    const pursuingBusinesses = getOpportunitiesByType(businesses, "pursuing");
                    const closedBusinesses = getOpportunitiesByType(businesses, "closed");

                    dispatch(readyToCommitSuccess({
                      businesses,
                      savedBusinesses,
                      pursuingBusinesses,
                      closedBusinesses
                    }));
                  }
                })
                .catch(() => dispatch(readyToCommitFail("Error")));
            }
          })
          .catch(() => dispatch(readyToCommitFail("Error")));*/
      }
      else {
        dispatch(readyToCommitFail(readyToCommitResponse.data.error));
      }
    })
    .catch(() => dispatch(readyToCommitFail("Error")));
});

export const readyToClose = business => ((dispatch, getState) => {
  dispatch(readyToCloseStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  const param = { business };

  CommonUtils.apiCall("post", "private", "investor/readyToClose", param, config)
    .then(readyToCloseResponse => {
      if (readyToCloseResponse.data.success) {
        CommonUtils.apiCall("get", "private", `shared/getBusiness/${business}`, {}, config)
          .then(currentBusinessResponse => {
            if (typeof currentBusinessResponse.data.success === "object" && currentBusinessResponse.data.success != null) {
              const currentOpenedBusiness = currentBusinessResponse.data.success;
              currentOpenedBusiness.id = business;
              dispatch(readyToCloseSuccess({
                currentOpenedBusiness
              }));
            }
            else {
              dispatch(readyToCloseFail("Error"))
            }
          })
          .catch(() => dispatch(readyToCloseFail("Error")));
        /*CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt", {}, config)
          .then(businessItemsCountResponse => {
            if (businessItemsCountResponse.data != null && parseInt(businessItemsCountResponse.data) === 0) {
              dispatch(readyToCloseSuccess({
                businesses: [],
                savedBusinesses: [],
                pursuingBusinesses: [],
                closedBusinesses: []
              }));
            }
            else if (businessItemsCountResponse.data != null) {
              const businessItemsCount = businessItemsCountResponse.data;
              CommonUtils.apiCall("get", "private", `shared/getBusinesses/0/${businessItemsCount}`, {}, config)
                .then(response => {
                  if (Array.isArray(response.data)) {
                    const businesses = response.data;
                    const savedBusinesses = getOpportunitiesByType(businesses, "saved");
                    const pursuingBusinesses = getOpportunitiesByType(businesses, "pursuing");
                    const closedBusinesses = getOpportunitiesByType(businesses, "closed");

                    dispatch(readyToCloseSuccess({
                      businesses,
                      savedBusinesses,
                      pursuingBusinesses,
                      closedBusinesses
                    }));
                  }
                  else {
                    dispatch(readyToCloseSuccess({
                      businesses: [],
                      savedBusinesses: [],
                      pursuingBusinesses: [],
                      closedBusinesses: []
                    }));
                  }
                })
                .catch(() => dispatch(readyToCloseFail("Error")));
            }
          })
          .catch(() => dispatch(readyToCloseFail(readyToCloseResponse.data.error)));*/
      }
      else {
        dispatch(readyToCloseFail(readyToCloseResponse.data.error));
      }
    })
    .catch(() => readyToCloseFail("Error"));
});

export const searchBusinessUsers = businessID => ((dispatch, getState) => {
  dispatch(searchBusinessUsersStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `admin/searchBusinessUsers/${businessID}/0/100`, {}, config)
    .then(response => {
      if (Array.isArray(response.data)) {
        dispatch(searchBusinessUsersSuccess({
          businessUsers: response.data,
        }));
      }
      else {
        dispatch(searchBusinessUsersFail(response.data.error));
      }
    })
    .catch(() => dispatch(searchBusinessUsersFail("Error")));
});

export const searchBusinessInvolveRequests = businessID => ((dispatch, getState) => {
  dispatch(searchBusinessInvolveRequestsStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `admin/searchBusinessInvolveRequests/${businessID}/0/100`, {}, config)
    .then(response => {
      if (Array.isArray(response.data)) {
        dispatch(searchBusinessInvolveRequestsSuccess({
          businessInvolveRequests: response.data,
        }));
      }
      else {
        dispatch(searchBusinessInvolveRequestsFail(response.data.error));
      }
    })
    .catch(() => dispatch(searchBusinessInvolveRequestsFail("Error")));
});

export const acceptInvolveRequest = params => ((dispatch, getState) => {
  dispatch(acceptInvolveRequestStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", `investor/acceptInvolveRequest`, params, config)
    .then(response => {
      if (Array.isArray(response.data)) {
        dispatch(acceptInvolveRequestSuccess());
      }
      else {
        dispatch(acceptInvolveRequestFail(response.data.error));
      }
    })
    .catch(() => dispatch(acceptInvolveRequestFail("Error")));
});

export const rejectInvolveRequest = params => ((dispatch, getState) => {
  dispatch(rejectInvolveRequestStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", `investor/rejectInvolveRequest`, params, config)
    .then(response => {
      if (Array.isArray(response.data)) {
        dispatch(rejectInvolveRequestSuccess());
      }
      else {
        dispatch(rejectInvolveRequestFail(response.data.error));
      }
    })
    .catch(() => dispatch(rejectInvolveRequestFail("Error")));
});

export const addInvolveRequest = params => ((dispatch, getState) => {
  dispatch(addInvolveRequestStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", `admin/addInvolveRequest`, params, config)
    .then(response => {
      if (Array.isArray(response.data)) {
        dispatch(addInvolveRequestSuccess());
      }
      else {
        dispatch(addInvolveRequestFail(response.data.error));
      }
    })
    .catch(() => dispatch(addInvolveRequestFail("Error")));
});

export const allowCommit = (params) => ((dispatch, getState) => {
  dispatch(allowCommitStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "admin/allowCommit", params, config)
    .then(response => {
      if (response.data.success) {
        dispatch(allowCommitSuccess({}));
      }
      else {
        dispatch(allowCommitFail(response.data.error));
      }
    })
    .catch(() => dispatch(allowCommitFail("Error")));
});

export const allowClose = (user, business) => ((dispatch, getState) => {
  dispatch(allowCloseStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };
  const params = { user, business };

  CommonUtils.apiCall("post", "private", "admin/allowClose", params, config)
    .then(response => {
      if (response.data.success) {
        dispatch(allowCloseSuccess({}));
      }
      else {
        dispatch(allowCloseFail(response.data.error));
      }
    })
    .catch(() => dispatch(allowCloseFail("Error")));
});

export const close = (user, business) => ((dispatch, getState) => {
  dispatch(closeStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };
  const params = { user, business };

  CommonUtils.apiCall("post", "private", "admin/close", params, config)
    .then(response => {
      if (response.data.success) {
        dispatch(closeSuccess({}));
      }
      else {
        dispatch(closeFail(response.data.error));
      }
    })
    .catch(() => dispatch(closeFail("Error")));
});

export const reopen = (user, business) => ((dispatch, getState) => {
  dispatch(reopenStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };
  const params = { user, business };

  CommonUtils.apiCall("post", "private", "admin/reOpen", params, config)
    .then(response => {
      if (response.data.success) {
        dispatch(reopenSuccess({}));
      }
      else {
        dispatch(reopenFail(response.data.error));
      }
    })
    .catch(() => dispatch(reopenFail("Error")));
});

export const setInvestmentInfo = (params) => ((dispatch, getState) => {
  dispatch(setInvestmentInfoStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "shared/setInvestmentInfo", params, config)
    .then((investmentInfoResponse) => {
      if (investmentInfoResponse.data.success) {
        CommonUtils.apiCall("get", "private", `admin/searchBusinessUsers/${params.business}/0/100`, {}, config)
          .then((businessUsersResponse) => {
            if (Array.isArray(businessUsersResponse.data)) {
              dispatch(setInvestmentInfoSuccess({
                businessUsers: businessUsersResponse.data,
              }));
            }
            else {
              dispatch(setInvestmentInfoFail(businessUsersResponse.data.error));
            }
          })
          .catch(() => dispatch(setInvestmentInfoFail("Error")));
      }
      else {
        dispatch(setInvestmentInfoFail(investmentInfoResponse.data.error));
      }
    })
    .catch(() => dispatch(setInvestmentInfoFail("Error")));
});

export const setComments = (params) => ((dispatch, getState) => {
  dispatch(setCommentsStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "admin/setComments", params, config)
    .then((investmentInfoResponse) => {
      if (investmentInfoResponse.data.success) {
        dispatch(setCommentsSuccess());
      }
      else {
        dispatch(setCommentsFail(investmentInfoResponse.data.error));
      }
    })
    .catch(() => dispatch(setCommentsFail("Error")));
});

export const setBusinessExpired = (params) => ((dispatch, getState) => {
  dispatch(setBusinessExpiredStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall('post', 'private', 'admin/setBusinessExpired', params, config)
    .then((expiredBusinessResponse) => {
      if (expiredBusinessResponse.data.success) {
        CommonUtils.apiCall('get', 'private', `shared/getBusiness/${params.id}`, {}, config)
          .then((currentOpenedBusinessResponse) => {
            if (typeof currentOpenedBusinessResponse.data.success === "object" && currentOpenedBusinessResponse.data.success != null) {
              // from API we are not getting an opportunity ID, that's why we write id for ourselves
              const currentOpenedBusiness = currentOpenedBusinessResponse.data.success;
              currentOpenedBusiness.id = params.id;
              dispatch(setBusinessExpiredSuccess({ currentOpenedBusiness }));
            } else {
              dispatch(setBusinessExpiredFail(currentOpenedBusinessResponse.data.error));
            }
          })
          .catch(() => dispatch(setBusinessExpiredFail('Error')));
      } else {
        dispatch(setBusinessExpiredFail('Error'));
      }
    })
    .catch(() => dispatch(setBusinessExpiredFail('Error')));
});

export const setPegateShareholding = (params) => ((dispatch, getState) => {
  dispatch(SetPegateShareholdingStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };
  CommonUtils.apiCall('post', 'private', 'admin/setPegateShareholding', params, config)
    .then((res) => {
      if (res.data.success) {
        dispatch(SetPegateShareholdingSuccess())
      } else {
        dispatch(SetPegateShareholdingFail('Error'));
      }
    })
    .catch(() => dispatch(SetPegateShareholdingFail('Error')));
});

export const removeBusinessInvestor = (params) => ((dispatch, getState) => {
  dispatch(removeBusinessInvestorStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };
  CommonUtils.apiCall('post', 'private', 'admin/removeBusinessInvestor', params, config)
    .then((res) => {
      if (res.data.success) {
        dispatch(removeBusinessInvestorSuccess())
      } else {
        dispatch(removeBusinessInvestorFail('Error'));
      }
    })
    .catch(() => dispatch(removeBusinessInvestorFail('Error')));
});

export const searchBusinessUserBids = (businessID, userId) => ((dispatch, getState) => {
  dispatch(searchBusinessUserBidsStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `admin/searchBusinessUserBids/${businessID}/${userId}`, {}, config)
    .then(response => {
      if (Array.isArray(response.data)) {
        dispatch(searchBusinessUserBidsSuccess({
          currentUserWinningBids: response.data,
        }));
      }
      else {
        dispatch(searchBusinessUserBidsFail(response.data.error));
      }
    })
    .catch(() => dispatch(searchBusinessUserBidsFail("Error")));
});
