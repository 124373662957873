export const GET_DICTIONARIES_START = "GET_DICTIONARIES_START";
export const GET_DICTIONARIES_SUCCESS = "GET_DICTIONARIES_SUCCESS";
export const GET_DICTIONARIES_FAIL = "GET_DICTIONARIES_FAIL";

export const GET_COUNTRIES_START = "GET_COUNTRIES_START";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL";

export const GET_STATES_START = "GET_STATES_START";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAIL = "GET_STATES_FAIL";

export const REMOVE_STATES = "REMOVE_STATES";

export const GET_CITIES_START = "GET_CITIES_START";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAIL = "GET_CITIES_FAIL";

export const GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_START = "GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_START";
export const GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_SUCCESS = "GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_SUCCESS";
export const GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_FAIL = "GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_FAIL";

export const FILL_USER_REGISTRATION_INFO = "FILL_USER_REGISTRATION_INFO";
export const RESET_USER_REGISTRATION_INFO = "RESET_USER_REGISTRATION_INFO";
export const REGISTER_USER_START = "REGISTER_USER_START";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export const CREATE_ADMIN_START = "CREATE_ADMIN_START";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_FAIL = "CREATE_ADMIN_FAIL";

export const SET_ONBOARDING_DATA_START = "SET_ONBOARDING_DATA_START";
export const SET_ONBOARDING_DATA_SUCCESS = "SET_ONBOARDING_DATA_SUCCESS";
export const SET_ONBOARDING_DATA_FAIL = "SET_ONBOARDING_DATA_FAIL";

export const GET_ONBOARDING_START = "GET_ONBOARDING_START";
export const GET_ONBOARDING_SUCCESS = "GET_ONBOARDING_SUCCESS";
export const GET_ONBOARDING_FAIL = "GET_ONBOARDING_FAIL";

export const SET_OPTIONS_START = "SET_OPTIONS_START";
export const SET_OPTIONS_SUCCESS = "SET_OPTIONS_SUCCESS";
export const SET_OPTIONS_FAIL = "SET_OPTIONS_FAIL";

export const GET_OPTIONS_START = "GET_OPTIONS_START";
export const GET_OPTIONS_SUCCESS = "GET_OPTIONS_SUCCESS";
export const GET_OPTIONS_FAIL = "GET_OPTIONS_FAIL";

export const CLEAR_OPTIONS_START = "CLEAR_OPTIONS_START";
export const CLEAR_OPTIONS_SUCCESS = "CLEAR_OPTIONS_SUCCESS";
export const CLEAR_OPTIONS_FAIL = "CLEAR_OPTIONS_FAIL";

export const ACTIVATE_USER_REGISTRATION_START = "ACTIVATE_USER_REGISTRATION_START";
export const ACTIVATE_USER_REGISTRATION_SUCCESS = "ACTIVATE_USER_REGISTRATION_SUCCESS";
export const ACTIVATE_USER_REGISTRATION_FAIL = "ACTIVATE_USER_REGISTRATION_FAIL";

export const SEND_RECOVERY_LINK_START = "SEND_RECOVERY_LINK_START";
export const SEND_RECOVERY_LINK_SUCCESS = "SEND_RECOVERY_LINK_SUCCESS";
export const SEND_RECOVERY_LINK_FAIL = "SEND_RECOVERY_LINK_FAIL";

export const RECOVER_PASSWORD_START = "RECOVER_PASSWORD_START";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_FAIL = "RECOVER_PASSWORD_FAIL";

export const LOGIN_USER_START = "LOGIN_USER_START";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const INIT_LOAD_START = "INIT_LOAD_START";
export const INIT_LOAD_SUCCESS = "INIT_LOAD_SUCCESS";
export const INIT_LOAD_FAIL = "INIT_LOAD_FAIL";

export const RESET_BUSINESS_LOADING = "RESET_BUSINESS_LOADING";

export const GET_PROFILE_START = "GET_PROFILE_START";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const CREATE_BUSINESS_START = "CREATE_BUSINESS_START";
export const CREATE_BUSINESS_SUCCESS = "CREATE_BUSINESS_SUCCESS";
export const CREATE_BUSINESS_FAIL = "CREATE_BUSINESS_FAIL";

export const UPDATE_BUSINESS_START = "UPDATE_BUSINESS_START";
export const UPDATE_BUSINESS_SUCCESS = "UPDATE_BUSINESS_SUCCESS";
export const UPDATE_BUSINESS_FAIL = "UPDATE_BUSINESS_FAIL";

export const ENABLE_BUSINESS_START = "ENABLE_BUSINESS_START";
export const ENABLE_BUSINESS_SUCCESS = "ENABLE_BUSINESS_SUCCESS";
export const ENABLE_BUSINESS_FAIL = "ENABLE_BUSINESS_FAIL";

export const DISABLE_BUSINESS_START = "DISABLE_BUSINESS_START";
export const DISABLE_BUSINESS_SUCCESS = "DISABLE_BUSINESS_SUCCESS";
export const DISABLE_BUSINESS_FAIL = "DISABLE_BUSINESS_FAIL";

export const GET_BUSINESS_START = "GET_BUSINESS_START";
export const GET_BUSINESS_SUCCESS = "GET_BUSINESS_SUCCESS";
export const GET_BUSINESS_FAIL = "GET_BUSINESS_FAIL";
export const REMOVE_CURRENT_BUSINESS = "REMOVE_CURRENT_BUSINESS";

export const GET_BUSINESS_INVOLVE_REQUEST_START = "GET_BUSINESS_INVOLVE_REQUEST_START";
export const GET_BUSINESS_INVOLVE_REQUEST_SUCCESS = "GET_BUSINESS_INVOLVE_REQUEST_SUCCESS";
export const GET_BUSINESS_INVOLVE_REQUEST_FAIL = "GET_BUSINESS_INVOLVE_REQUEST_FAIL";

export const SET_BUSINESS_FILTER = "SET_BUSINESS_FILTER";
export const RESET_BUSINESS_FILTER = "RESET_BUSINESS_FILTER";

export const GET_BUSINESSES_START = "GET_BUSINESSES_START";
export const GET_BUSINESSES_SUCCESS = "GET_BUSINESSES_SUCCESS";
export const GET_BUSINESSES_FAIL = "GET_BUSINESSES_FAIL";

export const GET_BUSINESSES_SUMMARY_START = "GET_BUSINESSESS_SUMMARY_START";
export const GET_BUSINESSESS_SUMMARY_SUCCESS = "GET_BUSINESSESS_SUMMARY_SUCCESS";
export const GET_BUSINESSESS_SUMMARY_FAIL = "GET_BUSINESSESS_SUMMARY_FAIL";

export const GET_PURSUING_BUSINESSES_START = "GET_PURSUING_BUSINESSES_START";
export const GET_PURSUING_BUSINESSES_SUCCESS = "GET_PURSUING_BUSINESSES_SUCCESS";
export const GET_PURSUING_BUSINESSES_FAIL = "GET_PURSUING_BUSINESSES_FAIL";

export const RESET_INVOLVE_REQUEST_DATA = "RESET_INVOLVE_REQUEST_DATA";

export const GET_VIRTUAL_DATA_ROOM_ITEMS_START = "GET_VIRTUAL_DATA_ROOM_ITEMS_START";
export const GET_VIRTUAL_DATA_ROOM_ITEMS_SUCCESS = "GET_VIRTUAL_DATA_ROOM_ITEMS_SUCCESS";
export const GET_VIRTUAL_DATA_ROOM_ITEMS_FAIL = "GET_VIRTUAL_DATA_ROOM_ITEMS_FAIL";

export const GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_START = "GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_START";
export const GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_SUCCESS = "GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_SUCCESS";
export const GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_FAIL = "GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_FAIL";

export const GET_BUSINESSES_BY_NAME_START = "GET_BUSINESSES_BY_NAME_START";
export const GET_BUSINESSES_BY_NAME_SUCCESS = "GET_BUSINESSES_BY_NAME_SUCCESS";
export const GET_BUSINESSES_BY_NAME_FAIL = "GET_BUSINESSES_BY_NAME_FAIL";

export const SET_BUSINESS_EXPIRED_START = "SET_BUSINESS_EXPIRED_START";
export const SET_BUSINESS_EXPIRED_SUCCESS = "SET_BUSINESS_EXPIRED_SUCCESS";
export const SET_BUSINESS_EXPIRED_FAIL = "SET_BUSINESS_EXPIRED_FAIL";

export const REMOVE_BUSINESS_INVESTOR_START = "REMOVE_BUSINESS_INVESTOR_START";
export const REMOVE_BUSINESS_INVESTOR_SUCCESS = "REMOVE_BUSINESS_INVESTOR_SUCCESS";
export const REMOVE_BUSINESS_INVESTOR_FAIL = "REMOVE_BUSINESS_INVESTOR_FAIL";

export const SEARCH_BUSINESS_USERS_BIDS_START = "SEARCH_BUSINESS_USERS_BIDS_START";
export const SEARCH_BUSINESS_USERS_BIDS_SUCCESS = "SEARCH_BUSINESS_USERS_BIDS_SUCCESS";
export const SEARCH_BUSINESS_USERS_BIDS_FAIL = "SEARCH_BUSINESS_USERS_BIDS_FAIL";

export const SET_SAVED_START = "SET_SAVED_START";
export const SET_SAVED_SUCCESS = "SET_SAVED_SUCCESS";
export const SET_SAVED_FAIL = "SET_SAVED_FAIL";

export const SET_INTERESTED_START = "SET_INTERESTED_START";
export const SET_INTERESTED_SUCCESS = "SET_INTERESTED_SUCCESS";
export const SET_INTERESTED_FAIL = "SET_INTERESTED_FAIL";

export const SET_MESSAGES_FILTER = "SET_MESSAGES_FILTER";
export const RESET_MESSAGES_FILTER = "RESET_MESSAGES_FILTER";

export const GET_MESSAGES_START = "GET_MESSAGES_START";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL";

export const GET_MESSAGE_START = "GET_MESSAGE_START";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_FAIL = "GET_MESSAGE_FAIL";

export const SET_DATA_ROOM_FILTER = "SET_DATA_ROOM_FILTER";
export const RESET_DATA_ROOM_FILTER = "RESET_DATA_ROOM_FILTER";
export const REMOVE_DATA_ROOM_ITEMS = "REMOVE_DATA_ROOM_ITEMS";

export const GET_DATA_ROOM_ITEMS_START = "GET_DATA_ROOM_ITEMS_START";
export const GET_DATA_ROOM_ITEMS_SUCCESS = "GET_DATA_ROOM_ITEMS_SUCCESS";
export const GET_DATA_ROOM_ITEMS_FAIL = "GET_DATA_ROOM_ITEMS_FAIL";

export const GET_DATA_ROOM_INNER_ITEMS_START = "GET_DATA_ROOM_INNER_ITEMS_START";
export const GET_DATA_ROOM_INNER_ITEMS_SUCCESS = "GET_DATA_ROOM_INNER_ITEMS_SUCCESS";
export const GET_DATA_ROOM_INNER_ITEMS_FAIL = "GET_DATA_ROOM_INNER_ITEMS_FAIL";

export const GET_SHARED_DATA_ROOM_ITEMS_START = "GET_SHARED_DATA_ROOM_ITEMS_START";
export const GET_SHARED_DATA_ROOM_ITEMS_SUCCESS = "GET_SHARED_DATA_ROOM_ITEMS_SUCCESS";
export const GET_SHARED_DATA_ROOM_ITEMS_FAIL = "GET_SHARED_DATA_ROOM_ITEMS_FAIL";

export const ADD_DATA_ROOM_ITEM_START = "ADD_DATA_ROOM_ITEM_START";
export const ADD_DATA_ROOM_ITEM_SUCCESS = "ADD_DATA_ROOM_ITEM_SUCCESS";
export const ADD_DATA_ROOM_ITEM_FAIL = "ADD_DATA_ROOM_ITEM_FAIL";

export const SET_DATA_ROOM_ITEM_ACCESS_TYPE_START = "SET_DATA_ROOM_ITEM_ACCESS_TYPE_START";
export const SET_DATA_ROOM_ITEM_ACCESS_TYPE_SUCCESS = "SET_DATA_ROOM_ITEM_ACCESS_TYPE_SUCCESS";
export const SET_DATA_ROOM_ITEM_ACCESS_TYPE_FAIL = "SET_DATA_ROOM_ITEM_ACCESS_TYPE_FAIL";

export const REMOVE_DATA_ROOM_ITEM_START = "REMOVE_DATA_ROOM_ITEM_START";
export const REMOVE_DATA_ROOM_ITEM_SUCCESS = "REMOVE_DATA_ROOM_ITEM_SUCCESS";
export const REMOVE_DATA_ROOM_ITEM_FAIL = "REMOVE_DATA_ROOM_ITEM_FAIL";

export const RENAME_DATA_ROOM_ITEM_START = "RENAME_DATA_ROOM_ITEM_START";
export const RENAME_DATA_ROOM_ITEM_SUCCESS = "RENAME_DATA_ROOM_ITEM_SUCCESS";
export const RENAME_DATA_ROOM_ITEM_FAIL = "RENAME_DATA_ROOM_ITEM_FAIL";

export const DOWNLOAD_DATA_ROOM_ITEM_START = "DOWNLOAD_DATA_ROOM_ITEM_START";
export const DOWNLOAD_DATA_ROOM_ITEM_SUCCESS = "DOWNLOAD_DATA_ROOM_ITEM_SUCCESS";
export const DOWNLOAD_DATA_ROOM_ITEM_FAIL = "DOWNLOAD_DATA_ROOM_ITEM_FAIL";

export const SEARCH_USERS_START = "SEARCH_USERS_START";
export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_FAIL = "SEARCH_USERS_FAIL";

export const SEARCH_ALL_USERS_START = "SEARCH_ALL_USERS_START";
export const SEARCH_ALL_USERS_SUCCESS = "SEARCH_ALL_USERS_SUCCESS";
export const SEARCH_ALL_USERS_FAIL = "SEARCH_ALL_USERS_FAIL";

export const SEARCH_NOT_INVOLVED_USERS_START = "SEARCH_NOT_INVOLVED_USERS_START";
export const SEARCH_NOT_INVOLVED_USERS_SUCCESS = "SEARCH_NOT_INVOLVED_USERS_SUCCESS";
export const SEARCH_NOT_INVOLVED_USERS_FAIL = "SEARCH_NOT_INVOLVED_USERS_FAIL";

export const GET_NOTIFY_ENABLED_START = "GET_NOTIFY_ENABLED_START";
export const GET_NOTIFY_ENABLED_SUCCESS = "GET_NOTIFY_ENABLED_SUCCESS";
export const GET_NOTIFY_ENABLED_FAIL = "GET_NOTIFY_ENABLED_FAIL";

export const SET_NOTIFY_ENABLED_START = "SET_NOTIFY_ENABLED_START";
export const SET_NOTIFY_ENABLED_SUCCESS = "SET_NOTIFY_ENABLED_SUCCESS";
export const SET_NOTIFY_ENABLED_FAIL = "SET_NOTIFY_ENABLED_FAIL";

export const SEARCH_USERS_FILTER_START = "SEARCH_USERS_FILTER_START";
export const SEARCH_USERS_FILTER_SUCCESS = "SEARCH_USERS_FILTER_SUCCESS";
export const SEARCH_USERS_FILTER_FAIL = "SEARCH_USERS_FILTER_FAIL";

export const SET_USERS_FILTER = "SET_USERS_FILTER";
export const RESET_USERS_FILTER = "RESET_USERS_FILTER";
export const RESET_USERS_LIST = "RESET_USERS_LIST";

export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const REMOVE_USER_DATA = "REMOVE_USER_DATA";

export const UPDATE_PERMISSIONS_START = "UPDATE_PERMISSIONS_START";
export const UPDATE_PERMISSIONS_SUCCESS = "UPDATE_PERMISSIONS_SUCCESS";
export const UPDATE_PERMISSIONS_FAIL = "UPDATE_PERMISSIONS_FAIL";

export const UPDATE_SETTINGS_START = "UPDATE_SETTINGS_START";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_FAIL = "UPDATE_SETTINGS_FAIL";

export const SET_ONBOARDED_START = "SET_ONBOARDED_START";
export const SET_ONBOARDED_SUCCESS = "SET_ONBOARDED_SUCCESS";
export const SET_ONBOARDED_FAIL = "SET_ONBOARDED_FAIL";
export const UNSET_ONBOARDED_START = "UNSET_ONBOARDED_START";
export const UNSET_ONBOARDED_SUCCESS = "UNSET_ONBOARDED_SUCCESS";
export const UNSET_ONBOARDED_FAIL = "UNSET_ONBOARDED_FAIL";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const ADD_MESSAGE_START = "ADD_MESSAGE_START";
export const ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS";
export const ADD_MESSAGE_FAIL = "ADD_MESSAGE_FAIL";

export const GET_REPORT_START = "GET_REPORT_START";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAIL = "GET_REPORT_FAIL";

export const GET_BUSINESS_REPORTS_START = "GET_BUSINESS_REPORTS_START";
export const GET_BUSINESS_REPORTS_SUCCESS = "GET_BUSINESS_REPORTS_SUCCESS";
export const GET_BUSINESS_REPORTS_FAIL = "GET_BUSINESS_REPORTS_FAIL";

export const GET_NEW_REPORT_START = "GET_NEW_REPORT_START";
export const GET_NEW_REPORT_SUCCESS = "GET_NEW_REPORT_SUCCESS";
export const GET_NEW_REPORT_FAIL = "GET_NEW_REPORT_FAIL";

export const GET_CLOSED_REPORTS_START = "GET_CLOSED_REPORTS_START";
export const GET_CLOSED_REPORTS_SUCCESS = "GET_CLOSED_REPORTS_SUCCESS";
export const GET_CLOSED_REPORTS_FAIL = "GET_CLOSED_REPORTS_FAIL";

export const GET_TRANSACTIONS_START = "GET_TRANSACTIONS_START";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL";

export const ADD_REPORT_START = "ADD_REPORT_START";
export const ADD_REPORT_SUCCESS = "ADD_REPORT_SUCCESS";
export const ADD_REPORT_FAIL = "ADD_REPORT_FAIL";

export const ADD_STATS_START = "ADD_STATS_START";
export const ADD_STATS_SUCCESS = "ADD_STATS_SUCCESS";
export const ADD_STATS_FAIL = "ADD_STATS_FAIL";

export const REMOVE_REPORTS = "REMOVE_REPORTS";

export const SIGN_NDA_START = "SIGN_NDA_START";
export const SIGN_NDA_SUCCESS = "SIGN_NDA_SUCCESS";
export const SIGN_NDA_FAIL = "SIGN_NDA_FAIL";

export const GET_NDA_START = "GET_NDA_START";
export const GET_NDA_SUCCESS = "GET_NDA_SUCCESS";
export const GET_NDA_FAIL = "GET_NDA_FAIL";

export const READY_TO_START_START = "READY_TO_START_START";
export const READY_TO_START_SUCCESS = "READY_TO_START_SUCCESS";
export const READY_TO_START_FAIL = "READY_TO_START_FAIL";

export const READY_TO_COMMIT_START = "READY_TO_COMMIT_START";
export const READY_TO_COMMIT_SUCCESS = "READY_TO_COMMIT_SUCCESS";
export const READY_TO_COMMIT_FAIL = "READY_TO_COMMIT_FAIL";

export const READY_TO_CLOSE_START = "READY_TO_CLOSE_START";
export const READY_TO_CLOSE_SUCCESS = "READY_TO_CLOSE_SUCCESS";
export const READY_TO_CLOSE_FAIL = "READY_TO_CLOSE_FAIL";

export const EMAIL_EXISTS_START = "EMAIL_EXISTS_START";
export const EMAIL_EXISTS_SUCCESS = "EMAIL_EXISTS_SUCCESS";
export const EMAIL_EXISTS_FAIL = "EMAIL_EXISTS_FAIL";
export const PHONE_EXISTS_START = "PHONE_EXISTS_START";
export const PHONE_EXISTS_SUCCESS = "PHONE_EXISTS_SUCCESS";
export const PHONE_EXISTS_FAIL = "PHONE_EXISTS_FAIL";

export const SEND_ACTIVATION_EMAIL_START = "SEND_ACTIVATION_EMAIL_START";
export const SEND_ACTIVATION_EMAIL_SUCCESS = "SEND_ACTIVATION_EMAIL_SUCCESS";
export const SEND_ACTIVATION_EMAIL_FAIL = "SEND_ACTIVATION_EMAIL_FAIL";

export const SET_MESSAGES_TABLE_PAGINATION = "SET_MESSAGES_TABLE_PAGINATION";
export const SET_MESSAGES_CURRENT_TAB = "SET_MESSAGES_CURRENT_TAB";

export const SET_USERS_TABLE_PAGINATION = "SET_USERS_TABLE_PAGINATION";

export const ADD_ACCESS_TO_DATA_ROOM_ITEM_START = "ADD_ACCESS_TO_DATA_ROOM_ITEM_START";
export const ADD_ACCESS_TO_DATA_ROOM_ITEM_SUCCESS = "ADD_ACCESS_TO_DATA_ROOM_ITEM_SUCCESS";
export const ADD_ACCESS_TO_DATA_ROOM_ITEM_FAIL = "ADD_ACCESS_TO_DATA_ROOM_ITEM_FAIL";

export const REMOVE_ACCESS_TO_DATA_ROOM_ITEM_START = "REMOVE_ACCESS_TO_DATA_ROOM_ITEM_START";
export const REMOVE_ACCESS_TO_DATA_ROOM_ITEM_SUCCESS = "REMOVE_ACCESS_TO_DATA_ROOM_ITEM_SUCCESS";
export const REMOVE_ACCESS_TO_DATA_ROOM_ITEM_FAIL = "REMOVE_ACCESS_TO_DATA_ROOM_ITEM_FAIL";

export const SEARCH_BUSINESS_ROOM_USERS_START = "SEARCH_BUSINESS_ROOM_USERS_START";
export const SEARCH_BUSINESS_ROOM_USERS_SUCCESS = "SEARCH_BUSINESS_ROOM_USERS_SUCCESS";
export const SEARCH_BUSINESS_ROOM_USERS_FAIL = "SEARCH_BUSINESS_ROOM_USERS_FAIL";

export const SEARCH_ROOM_USERS_START = "SEARCH_ROOM_USERS_START";
export const SEARCH_ROOM_USERS_SUCCESS = "SEARCH_ROOM_USERS_SUCCESS";
export const SEARCH_ROOM_USERS_FAIL = "SEARCH_ROOM_USERS_FAIL";
export const SET_USERS_SEARCH_ROOM_PAGINATION = "SET_USERS_SEARCH_ROOM_PAGINATION";

export const SEARCH_BUSINESS_USERS_START = "SEARCH_BUSINESS_USERS_START";
export const SEARCH_BUSINESS_USERS_SUCCESS = "SEARCH_BUSINESS_USERS_SUCCESS";
export const SEARCH_BUSINESS_USERS_FAIL = "SEARCH_BUSINESS_USERS_FAIL";

export const SEARCH_BUSINESS_INVOLVE_REQUESTS_START = "SEARCH_BUSINESS_INVOLVE_REQUESTS_START";
export const SEARCH_BUSINESS_INVOLVE_REQUESTS_SUCCESS = "SEARCH_BUSINESS_INVOLVE_REQUESTS_SUCCESS";
export const SEARCH_BUSINESS_INVOLVE_REQUESTS_FAIL = "SEARCH_BUSINESS_INVOLVE_REQUESTS_FAIL";

export const ADD_INVOLVE_REQUESTS_START = "ADD_INVOLVE_REQUESTS_START";
export const ADD_INVOLVE_REQUESTS_SUCCESS = "ADD_INVOLVE_REQUESTS_SUCCESS";
export const ADD_INVOLVE_REQUESTS_FAIL = "ADD_INVOLVE_REQUESTS_FAIL";

export const ACCEPT_INVOLVE_REQUEST_START = "ACCEPT_INVOLVE_REQUEST_START";
export const ACCEPT_INVOLVE_REQUEST_SUCCESS = "ACCEPT_INVOLVE_REQUEST_SUCCESS";
export const ACCEPT_INVOLVE_REQUEST_FAIL = "ACCEPT_INVOLVE_REQUEST_FAIL";

export const REJECT_INVOLVE_REQUEST_START = "REJECT_INVOLVE_REQUEST_START";
export const REJECT_INVOLVE_REQUEST_SUCCESS = "REJECT_INVOLVE_REQUEST_SUCCESS";
export const REJECT_INVOLVE_REQUEST_FAIL = "REJECT_INVOLVE_REQUEST_FAIL";

export const SEARCH_STATISTICS_USERS_START = "SEARCH_STATISTICS_USERS_START";
export const SEARCH_STATISTICS_USERS_SUCCESS = "SEARCH_STATISTICS_USERS_SUCCESS";
export const SEARCH_STATISTICS_USERS_FAIL = "SEARCH_STATISTICS_USERS_FAIL";

export const ALLOW_COMMIT_START = "ALLOW_COMMIT_START";
export const ALLOW_COMMIT_SUCCESS = "ALLOW_COMMIT_SUCCESS";
export const ALLOW_COMMIT_FAIL = "ALLOW_COMMIT_FAIL";

export const ALLOW_CLOSE_START = "ALLOW_CLOSE_START";
export const ALLOW_CLOSE_SUCCESS = "ALLOW_CLOSE_SUCCESS";
export const ALLOW_CLOSE_FAIL = "ALLOW_CLOSE_FAIL";

export const CLOSE_START = "CLOSE_START";
export const CLOSE_SUCCESS = "CLOSE_SUCCESS";
export const CLOSE_FAIL = "CLOSE_FAIL";

export const REOPEN_START = "REOPEN_START";
export const REOPEN_SUCCESS = "REOPEN_SUCCESS";
export const REOPEN_FAIL = "REOPEN_FAIL";

export const SET_ACTIVE_BUSINESS_TAB_KEY = "SET_ACTIVE_BUSINESS_TAB_KEY";
export const SET_ACTIVE_PORTFOLIO_TAB_KEY = "SET_ACTIVE_PORTFOLIO_TAB_KEY";
export const SET_ACTIVE_INVESTMENT_ID = "SET_ACTIVE_INVESTMENT_ID";

export const GET_TAGS_START = "GET_TAGS_START";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_FAIL = "GET_TAGS_FAIL";

export const TAG_USERS_START = "TAG_USERS_START";
export const TAG_USERS_SUCCESS = "TAG_USERS_SUCCESS";
export const TAG_USERS_FAIL = "TAG_USERS_FAIL";

export const UNTAG_USERS_START = "UNTAG_USERS_START";
export const UNTAG_USERS_SUCCESS = "UNTAG_USERS_SUCCESS";
export const UNTAG_USERS_FAIL = "UNTAG_USERS_FAIL";

export const ADD_TAG_START = "ADD_TAG_START";
export const ADD_TAG_SUCCESS = "ADD_TAG_SUCCESS";
export const ADD_TAG_FAIL = "ADD_TAG_FAIL";

export const RENAME_TAG_START = "RENAME_TAG_START";
export const RENAME_TAG_SUCCESS = "RENAME_TAG_SUCCESS";
export const RENAME_TAG_FAIL = "RENAME_TAG_FAIL";

export const REMOVE_TAG_START = "REMOVE_TAG_START";
export const REMOVE_TAG_SUCCESS = "REMOVE_TAG_SUCCESS";
export const REMOVE_TAG_FAIL = "REMOVE_TAG_FAIL";

export const GET_TAG_USERS_START = "GET_TAG_USERS_START";
export const GET_TAG_USERS_SUCCESS = "GET_TAG_USERS_SUCCESS";
export const GET_TAG_USERS_FAIL = "GET_TAG_USERS_FAIL";

export const GET_ALL_TAG_USERS_START = "GET_ALL_TAG_USERS_START";
export const GET_ALL_TAG_USERS_SUCCESS = "GET_ALL_TAG_USERS_SUCCESS";
export const GET_ALL_TAG_USERS_FAIL = "GET_ALL_TAG_USERS_FAIL";

export const SET_INVESTMENT_INFO_START = "SET_INVESTMENT_INFO_START";
export const SET_INVESTMENT_INFO_SUCCESS = "SET_INVESTMENT_INFO_SUCCESS";
export const SET_INVESTMENT_INFO_FAIL = "SET_INVESTMENT_INFO_FAIL";

export const SET_COMMENTS_START = "SET_COMMENTS_START";
export const SET_COMMENTS_SUCCESS = "SET_COMMENTS_SUCCESS";
export const SET_COMMENTS_FAIL = "SET_COMMENTS_FAIL";

export const ADD_NOTE_START = "ADD_NOTE_START";
export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS";
export const ADD_NOTE_FAIL = "ADD_NOTE_FAIL";

export const UPDATE_NOTE_START = "UPDATE_NOTE_START";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_FAIL = "UPDATE_NOTE_FAIL";

export const REMOVE_NOTE_START = "REMOVE_NOTE_START";
export const REMOVE_NOTE_SUCCESS = "REMOVE_NOTE_SUCCESS";
export const REMOVE_NOTE_FAIL = "REMOVE_NOTE_FAIL";

export const GET_NOTES_START = "GET_NOTES_START";
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS";
export const GET_NOTES_FAIL = "GET_NOTES_FAIL";

export const TOGGLE_SETTINGS_POPUP = "TOGGLE_SETTINGS_POPUP";

/// ---------------------- SHARED -------------------------

export const SEARCH_OFFERS_START = "SEARCH_OFFERS_START";
export const SEARCH_OFFERS_SUCCESS = "SEARCH_OFFERS_SUCCESS";
export const SEARCH_OFFERS_FAIL = "SEARCH_OFFERS_FAIL";

export const CLEAR_OFFERS = "CLEAR_OFFERS";

export const SET_ACTIVE_OFFERS_TAB_KEY = "SET_ACTIVE_OFFERS_TAB_KEY";

export const SET_OFFER_FILTERS = "SET_OFFER_FILTERS";
export const CLEAR_OFFER_FILTERS = "CLEAR_OFFER_FILTERS";

export const SEARCH_OFFER_BIDS_START = "SEARCH_OFFER_BIDS_START";
export const SEARCH_OFFER_BIDS_SUCCESS = "SEARCH_OFFER_BIDS_SUCCESS";
export const SEARCH_OFFER_BIDS_FAIL = "SEARCH_OFFER_BIDS_FAIL";

export const SEARCH_OFFER_BID_REQUESTS_START = "SEARCH_OFFER_BID_REQUESTS_START";
export const SEARCH_OFFER_BID_REQUESTS_SUCCESS = "SEARCH_OFFER_BID_REQUESTS_SUCCESS";
export const SEARCH_OFFER_BID_REQUESTS_FAIL = "SEARCH_OFFER_BID_REQUESTS_FAIL";

export const GET_OFFER_BID_REQUEST_START = "GET_OFFER_BID_REQUEST_START";
export const GET_OFFER_BID_REQUEST_SUCCESS = "GET_OFFER_BID_REQUEST_SUCCESS";
export const RESET_OFFER_BID_REQUEST = "RESET_OFFER_BID_REQUEST";
export const GET_OFFER_BID_REQUEST_FAIL = "GET_OFFER_BID_REQUEST_FAIL";

export const ACCEPT_BID_REQUEST_START = "ACCEPT_BID_REQUEST_START";
export const ACCEPT_BID_REQUEST_SUCCESS = "ACCEPT_BID_REQUEST_SUCCESS";
export const ACCEPT_BID_REQUEST_FAIL = "ACCEPT_BID_REQUEST_FAIL";

export const REJECT_BID_REQUEST_START = "REJECT_BID_REQUEST_START";
export const REJECT_BID_REQUEST_SUCCESS = "REJECT_BID_REQUEST_SUCCESS";
export const REJECT_BID_REQUEST_FAIL = "REJECT_BID_REQUEST_FAIL";

export const GET_OFFER_START = "GET_OFFER_START";
export const GET_OFFER_SUCCESS = "GET_OFFER_SUCCESS";
export const GET_OFFER_FAIL = "GET_OFFER_FAIL";

export const CLEAR_OFFER = "CLEAR_OFFER";

export const GET_BID_START = "GET_BID_START";
export const GET_BID_SUCCESS = "GET_BID_SUCCESS";
export const GET_BID_FAIL = "GET_BID_FAIL";

/// ---------------------- INVESTOR -------------------------

export const CREATE_OFFER_START = "CREATE_OFFER_START";
export const CREATE_OFFER_SUCCESS = "CREATE_OFFER_SUCCESS";
export const CREATE_OFFER_FAIL = "CREATE_OFFER_FAIL";

export const REQUEST_EXTERNAL_SALE_START = "REQUEST_EXTERNAL_SALE_START";
export const REQUEST_EXTERNAL_SALE_SUCCESS = "REQUEST_EXTERNAL_SALE_SUCCESS";
export const REQUEST_EXTERNAL_SALE_FAIL = "REQUEST_EXTERNAL_SALE_FAIL";

export const ADD_INTERNAL_BID_START = "ADD_INTERNAL_BID_START";
export const ADD_INTERNAL_BID_SUCCESS = "ADD_INTERNAL_BID_SUCCESS";
export const ADD_INTERNAL_BID_FAIL = "ADD_INTERNAL_BID_FAIL";
export const ADD_EXTERNAL_BID_START = "ADD_EXTERNAL_BID_START";
export const ADD_EXTERNAL_BID_SUCCESS = "ADD_EXTERNAL_BID_SUCCESS";
export const ADD_EXTERNAL_BID_FAIL = "ADD_EXTERNAL_BID_FAIL";

export const ADD_EXTERNAL_OVERBID = "ADD_EXTERNAL_OVERBID";

export const SEARCH_BIDS_START = "SEARCH_BIDS_START";
export const SEARCH_BIDS_SUCCESS = "SEARCH_BIDS_SUCCESS";
export const SEARCH_BIDS_FAIL = "SEARCH_BIDS_FAIL";

export const REMOVE_OFFER_INVESTOR_START = "REMOVE_OFFER_INVESTOR_START";
export const REMOVE_OFFER_INVESTOR_SUCCESS = "REMOVE_OFFER_INVESTOR_SUCCESS";
export const REMOVE_OFFER_INVESTOR_FAIL = "REMOVE_OFFER_INVESTOR_FAIL";

/// ---------------------- ADMIN -------------------------

export const EXTERNAL_PUBLISH_START = "EXTERNAL_PUBLISH_START";
export const EXTERNAL_PUBLISH_SUCCESS = "EXTERNAL_PUBLISH_SUCCESS";
export const EXTERNAL_PUBLISH_FAIL = "EXTERNAL_PUBLISH_FAIL";

export const CLOSE_OFFER_START = "CLOSE_OFFER_START";
export const CLOSE_OFFER_SUCCESS = "CLOSE_OFFER_SUCCESS";
export const CLOSE_OFFER_FAIL = "CLOSE_OFFER_FAIL";

export const MOVE_BID_START = "MOVE_BID_START";
export const MOVE_BID_SUCCESS = "MOVE_BID_SUCCESS";
export const MOVE_BID_FAIL = "MOVE_BID_FAIL";

export const INTERNAL_PUBLISH_START = "INTERNAL_PUBLISH_START";
export const INTERNAL_PUBLISH_SUCCESS = "INTERNAL_PUBLISH_SUCCESS";
export const INTERNAL_PUBLISH_FAIL = "INTERNAL_PUBLISH_FAIL";

export const REMOVE_OFFER_ADMIN_START = "REMOVE_OFFER_ADMIN_START";
export const REMOVE_OFFER_ADMIN_SUCCESS = "REMOVE_OFFER_ADMIN_SUCCESS";
export const REMOVE_OFFER_ADMIN_FAIL = "REMOVE_OFFER_ADMIN_FAIL";

export const SET_WON_START = "SET_WON_START";
export const SET_WON_SUCCESS = "SET_WON_SUCCESS";
export const SET_WON_FAIL = "SET_WON_FAIL";

export const SET_PEGATE_SHAREHOLDING_START = "SET_PEGATE_SHAREHOLDING_START";
export const SET_PEGATE_SHAREHOLDING_SUCCESS = "SET_PEGATE_SHAREHOLDING_SUCCESS";
export const SET_PEGATE_SHAREHOLDING_FAIL = "SET_PEGATE_SHAREHOLDING_FAIL";